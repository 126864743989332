import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams  } from "react-router-dom";
import axios from "../api/axios";
import { MdContentCopy } from 'react-icons/md';
import { MdArrowBackIosNew } from 'react-icons/md';
import GlobalContext from "../contexts/GlobalProvider";
import Common from "../utils/common.js"
import Toast from "../components/Toast";
import {CopyToClipboard} from 'react-copy-to-clipboard';



function DetailsRoom(props) {

    
    const {room_id} = useParams()
    const navigate = useNavigate()
    const { global } = useContext(GlobalContext)
    const [roomData, setRoomData] = useState({})
    const [updatePassword, setUpdatePassword] = useState("")
    const [editFinished, setEditFinished] = useState(true)
    const [toastMessage, setToastMessage] = useState({visible: false, message: ""})
    const [edit, setEdit] = useState(true)
    const [emptyRoom, setEmptyRoom] = useState(true)
    const codeInput = useRef(null)
    const editPasswordInput = useRef(null)
    const passwordInput = useRef(null)
    const [emptyRoomMeessage, setEmptyRoomMeessage] = useState("")
    const [micSettings, setMicSettings] = useState({enable: false, number: 5})

    
    const loadRoomInfo = async () => {
        axios.post("/rooms/"+room_id)
        .then(data => {

            var room = data.data.return_data
            room = JSON.parse(Common.base64Decode(room))
            setMicSettings({enable: room.multi_mic === 1, number: room.multi_mic_number})
            setRoomData(room)
            setUpdatePassword(room.master_password)
            setEmptyRoom(JSON.stringify(room) === "{}")
            setEmptyRoomMeessage(global.translator.room_no_exists)
        })
        .catch(err => {
            navigate('/login')
        })
       
    }



    const editPassword = (e, current_password) => {
        if(edit) {
            setEdit(false)
            return
        }

        var password = editPasswordInput.current.value
        if(password.length < 8) {
            setToastMessage({
                visible: true,
                message: global.translator.password_at_least_8_char
            })
            return
        }

        var data = {
            room_id: roomData.room_id,
            current_password: Common.sha256(roomData.master_password),
            password: password
        }

        axios.post("/rooms/password/update", data)
        .then(data => {

            if(data.data.status == 1) {
                setToastMessage({
                    visible: true,
                    message: global.translator.saved
                })
                setUpdatePassword(password)
                passwordInput.current.value = password
            }
            else {
                setToastMessage({
                    visible: true,
                    message: global.translator.error
                })
            }
            setEdit(true)
            
        })
        .catch(err => {
            setEdit(true)
            navigate('/login')
        })

    }

    const onMicChange = async (e) => {
        try {
            const data = await axios.post("/rooms/settings/mic", {enable: !micSettings.enable, number: micSettings.number, room_id: room_id})
            setToastMessage({
                visible: true,
                message: global.translator.saved
            })
            setMicSettings({enable: !micSettings.enable, number: micSettings.number})
        } catch (error) {
            
        }
    }

    const updateMicLength = (mode) => {
        if(mode === "inc" && micSettings.number <= 9) {
            setMicSettings({enable: micSettings.enable, number: micSettings.number+1})
        } else if(mode === "dec" && micSettings.number >= 3) {
            setMicSettings({enable: micSettings.enable, number: micSettings.number-1})
        }
    }

    const saveMicSettings = async () => {
        try {
            const data = await axios.post("/rooms/settings/mic", {enable: micSettings.enable, number: micSettings.number, room_id: room_id})
            setToastMessage({
                visible: true,
                message: global.translator.saved
            })
        } catch (error) {
            // console.log(error)
        }
    }


    useEffect(() => {
        loadRoomInfo()
    }, [])

    return (
        
        <div className="relative">
            
            <div className="flex items-center text-2xl mb-4 mt-2 hover:underline hover:text-main cursor-pointer" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew className="mr-3"/>
                {global.translator.rooms}
            </div>
            
            {emptyRoom ? (
                <div className={`w-full text-center mt-10 text-xl text-gray-700 ${emptyRoom?"":"hidden"}`}>{emptyRoomMeessage}</div>
            ) : (
                <div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    <div className="bg-white p-4 rounded shadow-sm flex flex-col cursor-pointer hover:bg-gray-50" onClick={() => {if(roomData.roots_count > 0) navigate("/rooms/"+room_id+"/roots")}}>
                        <span className="text-xl">{global.translator.roots}</span>
                        <span className="text-main text-xl mt-4 font-bold">{roomData.roots_count}</span>
                    </div>
                    <div className="bg-white p-4 rounded shadow-sm flex flex-col hover:bg-gray-50 cursor-pointer"  onClick={() => {if(roomData.users_count > 0) navigate("/rooms/"+room_id+"/users")}}>
                        <span className="text-xl">{global.translator.users}</span>
                        <span className="text-main text-xl mt-4 font-bold">{roomData.users_count}</span>
                    </div>
                    <div className="bg-white p-4 rounded shadow-sm flex flex-col hover:bg-gray-50 cursor-pointer"  onClick={() => {if(roomData.banned_count > 0) navigate("/rooms/"+room_id+"/banned")}}>
                        <span className="text-xl">{global.translator.banned}</span>
                        <span className="text-main text-xl mt-4 font-bold">{roomData.banned_count}</span>
                    </div>
                </div>
                <div className="bg-white p-4 shadow-dm border rounded mt-4 overflow-auto">
                   
                    <span className="text-xl text-gray-700">{global.translator.room_info}</span>
                    <table className="text-left overflow-hidden mt-4 border-separate border-spacing-y-6">
                        <tbody className="overflow-hidden">
                            <tr>
                                <th className="pr-2">{global.translator.room_name}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{roomData.name_ar}</td>
                            </tr>
                            <tr>
                                <th className="pr-2 whitespace-nowrap">{global.translator.network}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{roomData.country_ar}</td>
                            </tr>
                            <tr>
                                <th className="pr-2 whitespace-nowrap">{global.translator.subscription_date}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{roomData.date_creation}</td>
                            </tr>
                            <tr>
                                <th className="pr-2 whitespace-nowrap">{global.translator.end_date}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{roomData.date_expire} <b className="mx-2">({roomData.expire_days} {global.translator.day})</b></td>
                            </tr>
                            <tr className="align-baseline">
                                <th className="pr-2 whitespace-nowrap center">{global.translator.master_password}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap flex justify-center items-center align-baseline">
                                    <div className={`flex border rounded px-3 py-1 bg-gray-50 justify-center items-center ${edit?"flex":"hidden"}`}>
                                        <input className="text-center text-gray-500 bg-gray-50 focus:outline-none" type="password" defaultValue={updatePassword} ref={passwordInput} readOnly/> 
                                        <CopyToClipboard text={updatePassword} onCopy={() => setToastMessage({message: global.translator.copied, visible: true})}>
                                            <MdContentCopy className="ml-3 text-xl text-gray-600 hover:text-main cursor-pointer"/>
                                        </CopyToClipboard>
                                    </div>
                                    <div className={`border rounded px-3 py-1 bg-gray-50 justify-center items-center ${edit?"hidden":"flex"}`}>
                                        <input className="text-center text-gray-500 bg-gray-50 focus:outline-none" type="password" defaultValue={updatePassword} ref={editPasswordInput}/> 
                                    </div>
                                    <button className="px-3 hover:text-main cursor-pointer font-bold" onClick={(e) => editPassword(e, updatePassword)}>{edit?global.translator.edit:global.translator.save}</button>
                                </td>
                            </tr>
                            <tr className="align-baseline">
                                <th className="pr-2 whitespace-nowrap center">{global.translator.master_code}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap flex">
                                    <div className="flex border rounded px-3 py-1 bg-gray-50 justify-center items-center">
                                        <input className="text-center text-gray-500 bg-gray-50 focus:outline-none" type="password" defaultValue={roomData.master_code} ref={codeInput} readOnly/> 
                                        <CopyToClipboard text={roomData.master_code} onCopy={() => setToastMessage({message: global.translator.copied, visible: true})}>
                                            <MdContentCopy className="ml-3 text-xl text-gray-600 hover:text-main cursor-pointer"/>
                                        </CopyToClipboard>
                                    </div>
                                </td>
                            </tr>

                            <tr className="align-top">
                                <th className="pr-4">{global.translator.joint_speaking}</th>
                                <th className="pr-4">:</th>
                                <td className="whitespace-nowrap text-gray-600">
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" value="" className="sr-only peer" checked={micSettings.enable} onChange={onMicChange}/>
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                    </label>
                                </td>
                            </tr>

                            {micSettings.enable && (
                                <tr>
                                    <th className="pr-4">{global.translator.mic_number}</th>
                                    <th className="pr-4">:</th>
                                    <td className="whitespace-nowrap text-gray-600">
                                        <div className="flex">
                                            <div className="flex">
                                                <button className="text-xl w-8 h-8 bg-gray-200 hover:bg-gray-300" onClick={() => updateMicLength("dec")}>-</button>
                                                <div className="w-8 h-8 bg-gray-200 flex items-center justify-center">{micSettings.number}</div>
                                                <button className="text-xl w-8 h-8 bg-gray-200 hover:bg-gray-300" onClick={() => updateMicLength("inc")}>+</button>
                                            </div>
                                            <button className="px-4 hover:text-main cursor-pointer font-bold" onClick={saveMicSettings}>{global.translator.save}</button>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    
                    
                </div>
            </div>
            )}

            <Toast message={toastMessage} setToastMessage={setToastMessage} />
            
        </div>
    );
}


  
export default DetailsRoom;
  

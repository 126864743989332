import React, {createContext, useState} from "react";

const AuthContext = createContext()

var initialState = {
    isLogged: false
}

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(initialState)
    return (
        <AuthContext.Provider value={{auth, setAuth}} >
            {children}
        </AuthContext.Provider>
    )
}


export default AuthContext;
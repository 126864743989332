import sha256  from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import { Buffer } from "buffer";


export default class Common {


    static base64Encode(text) {
        let buff = Buffer.from(text);
        return buff.toString('base64');
    }

    static base64Decode(text) {
        return Buffer.from(text, 'base64').toString("utf-8")
    }
    
    static sha256(text) {
        return Base64.stringify(sha256(text))
    }
    

}
// var common = new Common()
// module.exports = {common}
import React, {createContext, useContext, useState} from "react";

const StateContext = createContext()

const initialState = {
    lang: "er",
}

export const ContextProvider = ({ children }) => {
    const [state, setState] = useState(initialState)
    return (
        <StateContext.Provider
            value={{state, setState}}
        >
            {children}
        </StateContext.Provider>
    )
}


export const useStateContext = () => useContext(StateContext)
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams  } from "react-router-dom";
import axios from "../api/axios";
import { MdContentCopy } from 'react-icons/md';
import { BiEditAlt } from 'react-icons/bi';
import { MdArrowBackIosNew } from 'react-icons/md';
import GlobalContext from "../contexts/GlobalProvider";
import Common from "../utils/common.js"
import Toast from "../components/Toast";
import {CopyToClipboard} from 'react-copy-to-clipboard';



function RootDetails(props) {

    
    const {root_id, room_id} = useParams()
    const navigate = useNavigate()
    const { global } = useContext(GlobalContext)
    const [rootData, setRootData] = useState({})
    const [updatePassword, setUpdatePassword] = useState("")
    const [editFinished, setEditFinished] = useState(true)
    const [toastMessage, setToastMessage] = useState({visible: false, message: ""})
    const [edit, setEdit] = useState(true)
    const [emptyData, setEmptyData] = useState(true)
    const codeInput = useRef(null)
    const editPasswordInput = useRef(null)
    const passwordInput = useRef(null)
    const [emptyDataMeessage, setEmptyDataMeessage] = useState("")
    const [locked, setLocked] = useState(false)

    
    const loadRootDetails = async () => {
        axios.post("/rooms/roots/"+root_id, {room_id: room_id})
        .then(data => {
            var root = data.data.return_data
            root = JSON.parse(Common.base64Decode(root))
            console.log(root)
            setRootData(root)
            setUpdatePassword(root.password)
            setLocked(root.lock_device !== "")
            // passwordInput.current.value = root.password
            setEmptyData(JSON.stringify(root) === "{}")
            // setEmptyRoomMeessage(global.translator.room_no_exists)
        })
        .catch(err => {
            // navigate('/login')
        })
       
    }



    const editPassword = (e, current_password) => {
        if(edit) {
            setEdit(false)
            return
        }

        var password = editPasswordInput.current.value
        if(password.length < 8) {
            setToastMessage({
                visible: true,
                message: global.translator.password_at_least_8_char
            })
            return
        }

        var data = {
            room_id: rootData.room_id,
            root_id: root_id,
            current_password: Common.sha256(rootData.password),
            password: password
        }

        axios.post("/rooms/root/password/update", data)
        .then(data => {

            if(data.data.status == 1) {
                setToastMessage({
                    visible: true,
                    message: global.translator.saved
                })
                setUpdatePassword(password)
                passwordInput.current.value = password
            }
            else {
                setToastMessage({
                    visible: true,
                    message: global.translator.error
                })
            }
            setEdit(true)
            
        })
        .catch(err => {
            setEdit(true)
            navigate('/login')
        })

    }


    useEffect(() => {
        loadRootDetails()
    }, [])

    const handleLockChange = async () => {
        try {
            
            var body = {
                room_id: rootData.room_id,
                root_id: root_id,
                lock: !locked
            }
            const data = (await axios.post("/rooms/root/lock", body)).data
            console.log(data)

            setLocked(!locked)
        } catch (error) {
            
        }
    }

    return (
        
        <div className="relative">
            
            <div className="flex items-center text-2xl mb-4 mt-2 hover:underline hover:text-main cursor-pointer" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew className="mr-3"/>
                {global.translator.roots_list}
            </div>
            
            {emptyData ? (
                <div className={`w-full text-center mt-10 text-xl text-gray-700 ${emptyData?"":"hidden"}`}>{emptyDataMeessage}</div>
            ) : (
                <div>
                
                <div className="bg-white p-4 shadow-dm border rounded mt-4 overflow-auto">
                   
                    <span className="text-xl text-gray-700 border-b flex pb-2">{global.translator.root_info}</span>
                    <table className="text-left overflow-hidden mt-4">
                        <tbody className="overflow-hidden">
                            <tr>
                                <th className="pr-2 py-3">{global.translator.root_name}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{rootData.nickname}</td>
                            </tr>
                            <tr>
                                <th className="pr-2 py-3">{global.translator.room_name}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{rootData.name_ar}</td>
                            </tr>
                            <tr>
                                <th className="pr-2 py-3 whitespace-nowrap">{global.translator.subscription_date}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{rootData.date_creation}</td>
                            </tr>
                            <tr>
                                <th className="pr-2 py-3 whitespace-nowrap">{global.translator.end_date}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{rootData.end_date} <b className="mx-2">({rootData.expire_days} {global.translator.day})</b></td>
                            </tr>
                            <tr>
                                <th className="pr-2 py-3 whitespace-nowrap">{global.translator.lock}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" value="" className="sr-only peer" checked={locked} onChange={handleLockChange}/>
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                    </label>
                                </td>
                            </tr>
                            <tr className="align-baseline">
                                <th className="pr-2 py-3 whitespace-nowrap center">{global.translator.password}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap flex justify-center items-center align-baseline">
                                    <div className={`flex border rounded px-3 py-1 bg-gray-50 justify-center items-center ${edit?"flex":"hidden"}`}>
                                        <input className="text-center text-gray-500 bg-gray-50 focus:outline-none" type="password" defaultValue={updatePassword} ref={passwordInput} readOnly/> 
                                        <CopyToClipboard text={updatePassword} onCopy={() => setToastMessage({message: global.translator.copied, visible: true})}>
                                            <MdContentCopy className="ml-3 text-xl text-gray-600 hover:text-main cursor-pointer"/>
                                        </CopyToClipboard>
                                    </div>
                                    <div className={`border rounded px-3 py-1 bg-gray-50 justify-center items-center ${edit?"hidden":"flex"}`}>
                                        <input className="text-center text-gray-500 bg-gray-50 focus:outline-none" type="password" defaultValue={updatePassword} ref={editPasswordInput}/> 
                                    </div>
                                    <button className="px-3 hover:text-main cursor-pointer font-bold" onClick={(e) => editPassword(e, updatePassword)}>{edit?global.translator.edit:global.translator.save}</button>
                                </td>
                            </tr>
                            <tr className="align-baseline">
                                <th className="pr-2 py-3 whitespace-nowrap center">{global.translator.code}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap flex">
                                    <div className="flex border rounded px-3 py-1 bg-gray-50 justify-center items-center">
                                        <input className="text-center text-gray-500 bg-gray-50 focus:outline-none" type="password" defaultValue={rootData.code} ref={codeInput} readOnly/> 
                                        <CopyToClipboard text={rootData.code} onCopy={() => setToastMessage({message: global.translator.copied, visible: true})}>
                                            <MdContentCopy className="ml-3 text-xl text-gray-600 hover:text-main cursor-pointer"/>
                                        </CopyToClipboard>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        
                        {/* <div className="flex items-center mt-8 mb-4">
                            <input id="default-checkbox" type="checkbox" value="" className="w-5 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-600">{global.translator.lock}</label>
                        </div> */}
                        
                    </table>
                </div>
            </div>
            )}

            <Toast message={toastMessage} setToastMessage={setToastMessage} />
            
        </div>
    );
}


  
export default RootDetails;
  

import React, { useEffect } from 'react'
import axios from '../api/axios'
import { useLocation, Navigate, Outlet, useNavigate} from "react-router-dom";

function Logout() {

    const navigate = useNavigate()

    useEffect(() => {
        axios.post("/logout")
        .then(data => {
            navigate("/login")
        })
        .catch(err => {
        })
    }, [])

    return (
      <div>Loging out ....</div>
    )
  }

export default Logout
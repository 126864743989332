import React, {createContext, useState} from "react";
// import lang_en from "../assets/lang/en.json"
import lang_ar from "../assets/lang/ar.json"

const GlobalContext = createContext()

const initialState = {
    translator: lang_ar,
}

export const GlobalProvider = ({ children }) => {
    const [global, setGlobal] = useState(initialState)
    return (
        <GlobalContext.Provider value={{global, setGlobal}} >
            {children}
        </GlobalContext.Provider>
    )
}


export default GlobalContext;
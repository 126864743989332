import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../api/axios'
import GlobalContext from '../contexts/GlobalProvider'
import Common from '../utils/common'
import { MdArrowBackIosNew } from 'react-icons/md';
import { TbArrowsSort } from 'react-icons/tb';

function Names() {


    const {room_id} = useParams()
    const navigate = useNavigate()
    const { global } = useContext(GlobalContext)
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])


    const loadDataList = async () => {
        try {
            var data = await axios.post("/names/list");
            data = data.data.return_data
            data = JSON.parse(Common.base64Decode(data))
            setData(data)
            setAllData(data)
        } catch (error) {
            navigate('/login')
        }
        return null
    }

    const sortData = (key) => {
        var data_ = JSON.parse(JSON.stringify(data))
        if(data_.length >= 2) {
            if(typeof data_[0][key] === "number") {
                if(data_[0][key] > data_[data_.length - 1][key]) data_.sort((a, b) => a[key] - b[key])
                else data_.sort((a, b) => b[key] - a[key])
            }
            else {
                if(data_[0][key] > data_[data_.length - 1][key]) data_.sort((a, b) => a[key].localeCompare(b[key]))
                else data_.sort((a, b) => b[key].localeCompare(a[key]))
            }
            
            setData(data_)
        }
    }

    const getTypeName = type => {
        if(type === 0) return "--"
        if(type === 1) return global.translator.protected
        if(type === 6) return global.translator.background
        if(type === 7) return global.translator.vip
        if(type === 8) return global.translator.support
        return global.translator.normal
    }

    const onSearch = e => {
        var nickname = e.target.value
        var list = []
        for(let name of allData) {
            if(name.nickname.includes(nickname)) list.push(name)
        }
        setData(list)
    }

    useEffect(() => {
        loadDataList()
    }, [])

    return (
        <div>
            <div className="flex items-center text-2xl mb-4 mt-2 hover:underline hover:text-main cursor-pointer" onClick={() => navigate("/", {replace: true})}>
                <MdArrowBackIosNew className="mr-3"/>
                {global.translator.home}
            </div>

            <div className='bg-white p-4 rounded-md border'>
                <div className='text-xl mb-2'>{global.translator.names_list}</div>

                <input className="mb-4 border h-10 text-center form-control rounded bg-gray-50 border-gray-300" type="text" placeholder={global.translator.search} onChange={e=>onSearch(e)}/> 

                <div className="overflow-x-auto relative rounded-md border border-gray-300">
                    <table className="w-full">
                        <thead className="w-full text-sm text-left text-gray-500 bg-gray-100">
                            <tr>
                                <th className="p-3 whitespace-nowrap"><div className='flex'>#<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("nickname")}/></div></th>
                                <th className="p-3 whitespace-nowrap"><div className='flex'>{global.translator.name}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("nickname")}/></div></th>
                                <th className="p-3 whitespace-nowrap"><div className='flex'>{global.translator.type2}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("type")}/></div></th>
                                <th className="p-3 whitespace-nowrap"><div className='flex'>{global.translator.expire} ({global.translator.day}) <TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("expire_days")}/></div></th>
                            </tr>
                        </thead>
                        <tbody className="w-full text-sm text-left text-gray-500 bg-white">
                            {data.map((name, index) => (
                                <tr key={index} className="border-t hover:bg-gray-100 cursor-pointer" onClick={()=>navigate("/names/"+name.id)}>
                                    <th className="p-3 whitespace-nowrap">
                                        <span className="font-normal">{name.id}</span>
                                    </th>
                                    <th className="p-3 whitespace-nowrap">
                                        <span className="font-normal">{name.nickname}</span>
                                    </th>
                                    <th className="p-3 whitespace-nowrap">
                                        <span className="font-bold">{getTypeName(name.name_type)}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{name.expire_days}</span>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
  }

export default Names
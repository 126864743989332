import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../api/axios'
import GlobalContext from '../contexts/GlobalProvider'
import Common from '../utils/common'
import { MdArrowBackIosNew } from 'react-icons/md';
import { TbArrowsSort } from 'react-icons/tb';

function Users() {

    const {room_id} = useParams()
    const navigate = useNavigate()
    const { global } = useContext(GlobalContext)
    const [data, setData] = useState([])


    const loadDataList = async () => {
        try {
            var data = await axios.post("/rooms/"+room_id+"/users");
            data = data.data.return_data
            data = JSON.parse(Common.base64Decode(data))
            setData(data)
        } catch (error) {
            navigate('/login')
        }
        return null
    }

    const sortData = (key) => {
        var data_ = JSON.parse(JSON.stringify(data))
        if(data_.length >= 2) {
            if(typeof data_[0][key] === "number") {
                if(data_[0][key] > data_[data_.length - 1][key]) data_.sort((a, b) => a[key] - b[key])
                else data_.sort((a, b) => b[key] - a[key])
            }
            else {
                if(data_[0][key] > data_[data_.length - 1][key]) data_.sort((a, b) => a[key].localeCompare(b[key]))
                else data_.sort((a, b) => b[key].localeCompare(a[key]))
            }
            
            setData(data_)
        }
    }

    const getTypeName = type => {
        if(type === 1) return global.translator.member
        if(type === 2) return global.translator.admin
        if(type === 3) return global.translator.super_admin
        if(type === 4) return global.translator.master
        if(type === 5) return global.translator.master
        if(type === 6) return global.translator.master
        if(type === 7) return global.translator.root
        return global.translator.guest
    }

    const getTypeColor = type => {
        if(type === 1) return "#9145d3"
        if(type === 2) return "#1107b5"
        if(type === 3) return "#20920f"
        if(type === 4) return "#cd0c0d"
        if(type === 5) return "#db17e8"
        if(type === 6) return "#cd0c0d"
        if(type === 7) return "#ff9900"
        return "#000000"
    }

    const convertMilliseconds = sec => {

        var time = parseInt(sec / (60 * 60 * 24))
        if(time > 0) return time+" "+global.translator.day

        time = parseInt(sec / (60 * 60))
        if(time > 0) return time+" "+global.translator.hour

        time = parseInt(sec / (60))
        if(time > 0) return time+" "+global.translator.minute

        return sec+" "+global.translator.second
    }

    useEffect(() => {
        loadDataList()
    }, [])

    return (
        <div>
            <div className="flex items-center text-2xl mb-4 mt-2 hover:underline hover:text-main cursor-pointer" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew className="mr-3"/>
                {global.translator.room_info}
            </div>

            <div className='bg-white p-4 rounded-md border'>
                <div className='text-xl mb-2'>{global.translator.users_list}</div>

                <div className="overflow-x-auto relative  rounded-md border border-gray-300">
                    <table className="w-full">
                        <thead className="w-full text-sm text-left text-gray-500 bg-gray-100">
                            <tr>
                                <th className="p-3 whitespace-nowrap"><div className='flex'>{global.translator.name}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("nickname")}/></div></th>
                                <th className="p-3 whitespace-nowrap"><div className='flex'>{global.translator.type}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("type")}/></div></th>
                                <th className="p-3 whitespace-nowrap"><div className='flex'>{global.translator.country} <TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("country_ar")}/></div></th>
                                <th className="p-3 whitespace-nowrap"><div className='flex'>{global.translator.presence_duration} <TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("login_duration")}/></div></th>
                            </tr>
                        </thead>
                        <tbody className="w-full text-sm text-left text-gray-500 bg-white">
                            {data.map((user, index) => (
                                <tr key={index} className="border-t hover:bg-gray-100 cursor-pointer">
                                    <th className="p-3 whitespace-nowrap">
                                        <span className="font-normal">{user.nickname}</span>
                                    </th>
                                    <th className="p-3 whitespace-nowrap">
                                        <span className="font-bold" style={{color: getTypeColor(user.type)}}>{getTypeName(user.type)}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{user.country_ar}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{convertMilliseconds(user.login_duration)}</span>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}



export default Users
import { useLocation, Navigate, Outlet, useNavigate} from "react-router-dom";
import { useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import axios from "../api/axios";

const RequireAuth = () => {

    const { auth } = useContext(AuthContext)
    const location = useLocation()
    const isLogged = sessionStorage.getItem("isLogged")
    const navigate = useNavigate()

    useEffect(() => {
        
        axios.post("/protected")
        .then(data => {
            // console.log(data.data)
        })
        .catch(error => {
            // if(error.response.status === 401) {
            //     navigate("/login")
            // }
            navigate("/login")
        })

    }, [])

    if(isLogged) {
        return (<Outlet />)
    }
    else {
        return(<Navigate to="/login" state={{from: location}} replace/>)
    }

}

export default RequireAuth;
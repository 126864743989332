import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { MdArrowBackIosNew } from 'react-icons/md';
// import { FiMoreVertical } from 'react-icons/fi';
// import { TbArrowsSort } from 'react-icons/tb';
import GlobalContext from "../contexts/GlobalProvider";
import Common from "../utils/common.js"



function Rooms() {

    const navigate = useNavigate()
    const { global } = useContext(GlobalContext)
    const [roomsData, setRoomsData] = useState([])


    const loadRoomsList = async () => {
        try {
            if(roomsData.length > 0) return
            var data = await axios.post("/rooms/list");
            var rooms = data.data.return_data
            rooms = JSON.parse(Common.base64Decode(rooms))
            setRoomsData(rooms)
        } catch (error) {
            navigate('/login')
        }
        return null
    }


    const openRoom = (room_id) => {
        navigate("/rooms/"+room_id)
    }

    useEffect(() => {
        loadRoomsList()
    }, [])

    return (
        <div className="flex flex-col">

            <div className="flex items-center text-2xl mb-4 mt-2 hover:underline hover:text-main cursor-pointer" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew className="mr-3"/>
                {global.translator.home}
            </div>
                
            <div className="bg-white p-4 rounded-md border">
                <span className="text-2xl">{global.translator.rooms_list}</span>

                <div className="overflow-x-auto relative  rounded-md border border-gray-300 mt-2">
                    <table className="w-full">
                        <thead className="w-full text-sm text-left text-gray-500 bg-gray-100">
                            <tr>
                                <th className="p-3"><span>{global.translator.id}</span></th>
                                <th className="p-3"><span>{global.translator.room_name}</span></th>
                            </tr>
                        </thead>
                        <tbody className="w-full text-sm text-left text-gray-500 bg-white">
                            {roomsData.map((room, index) => (
                                <tr key={index} className="border-t hover:bg-gray-100 cursor-pointer" onClick={() => openRoom(room.room_id)}>
                                    <th className="p-3">
                                        <span className="font-normal">{room.room_id}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{room.name_ar}</span>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

  
export default Rooms;
  

  
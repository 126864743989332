import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom"
import { ContextProvider } from './contexts/ContextProvider';
import { AuthProvider } from './contexts/AuthProvider';
import { GlobalProvider } from './contexts/GlobalProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId='783974924365-rigjedouaro2asq7eft0g2pimvafqfaa.apps.googleusercontent.com'>
      <BrowserRouter>
        <ContextProvider>
          <GlobalProvider>
              <AuthProvider>
                  <App />
              </AuthProvider>
          </GlobalProvider>
        </ContextProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);



import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../api/axios'
import { MdArrowBackIosNew, MdDelete } from 'react-icons/md';
import GlobalContext from '../contexts/GlobalProvider'
import Common from '../utils/common'
import { TbArrowsSort } from 'react-icons/tb';
import Toast from "../components/Toast";

function BannedUsers() {


    const {room_id} = useParams()
    const navigate = useNavigate()
    const { global } = useContext(GlobalContext)
    const [dataList, setDataList] = useState([])
    const [allDataList, setAllDataList] = useState([])
    const [banType, setBanType] = useState("id")
    const [loading, setLoading] = useState(true)
    const [toastMessage, setToastMessage] = useState({visible: false, message: ""})

    const loadDataList = async () => {
        axios.post("/rooms/banned/list", {room_id: room_id})
        .then(data => {

            var banned_server = data.data.return_data
            banned_server = JSON.parse(Common.base64Decode(banned_server))
            
            setAllDataList(banned_server)
            setBanType("id")
            
            
            setLoading(false)
            return true
        })
        .catch(err => {
            navigate('/login')
        })
       
    }

    const getBanList = (type) => {
        const result = []
        for(let ban of allDataList) {
            if(ban.ban_type === type) result.push(ban)
        }
        return result
    }

    const sortData = (key) => {
        var list = JSON.parse(JSON.stringify(dataList))
        if(list.length >= 2) {
            if(typeof list[0][key] === "number") {
                if(list[0][key] > list[list.length - 1][key]) list.sort((a, b) => a[key] - b[key])
                else list.sort((a, b) => b[key] - a[key])
            }
            else {
                if(list[0][key] > list[list.length - 1][key]) list.sort((a, b) => a[key].localeCompare(b[key]))
                else list.sort((a, b) => b[key].localeCompare(a[key]))
            }
            
            setDataList(list)
        }
    }
  

    const onSearch = e => {
        var nickname = e.target.value
        var list = []
        for(let item of allDataList) {
            if(item.nickname.includes(nickname)) list.push(item)
        }
        setDataList(list)
    }
    
    const onChange = e => {
        const type = e.target.dataset.type
        setBanType(type)
    }

    const getDuration = (duration) => {
        if(duration < 0) return global.translator.permanent
        return convertTime(duration)
    }

    const deleteBan = async (id) => {
        try {
            const data = (await axios.post("/rooms/banned/delete", {id: id})).data
            if(data.status === 1) {
                setToastMessage({
                    visible: true,
                    message: global.translator.ban_deleted
                })

                var list = []
                for(let item of allDataList) {
                    if(item.id !== data.id) list.push(item)
                }
                setAllDataList(list)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        loadDataList()
    }, [])

    useEffect(() => {
        const list = getBanList(banType)
        setDataList(list)
    }, [banType, allDataList])

    return (
        <div className="relative">

            <div className="flex items-center text-2xl mb-4 mt-2 hover:underline hover:text-main cursor-pointer" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew className="mr-3"/>
                {global.translator.room_info}
            </div>

            <div className='bg-white p-4 rounded-md border'>
                <div className='text-xl mb-2'>{global.translator.banned_list}</div>
                
                <input className="border h-10 text-center form-control rounded bg-gray-50 border-gray-300" type="text" placeholder={global.translator.search} onChange={e=>onSearch(e)}/> 

                

                <div className="flex rounded-md shadow-sm my-5 font-sans" role="group">
                    <button onClick={onChange} type="button" data-type="id" className={`w-20 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-l-lg hover:bg-gray-100 ${banType=="id"?"bg-gray-200":"bg-white"}`}>
                        ID
                    </button>
                    <button onClick={onChange} type="button" data-type="ip" className={`w-20 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 ${banType=="ip"?"bg-gray-200":"bg-white"}`}>
                        IP
                    </button>
                    <button onClick={onChange} type="button" data-type="server" className={`w-20 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 ${banType=="server"?"bg-gray-200":"bg-white"}`}>
                        Server
                    </button>
                </div>

                
                <div className="overflow-x-auto relative  rounded-md border border-gray-300">
                    <table className="w-full">
                        <thead className="w-full text-sm text-left text-gray-500 bg-gray-100">
                            <tr>
                                <th className="p-3 flex"># <TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("id")}/></th>
                                <th className="p-3"><div className='flex'>{global.translator.name}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("nickname")}/></div></th>
                                <th className="p-3"><div className='flex'>{global.translator.ip}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={() => sortData("ip")}/></div></th>

                                <th className="p-3"><div className='flex'>{global.translator.admin_name}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main'/></div></th>

                                <th className="p-3"><div className='flex'>{global.translator.country}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main'/></div></th>

                                <th className="p-3"><div className='flex'>{global.translator.ban_date}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main'/></div></th>

                                <th className="p-3"><div className='flex'>{global.translator.duration}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main'/></div></th>
                                <th className="p-3"><div className='flex'></div></th>
                            </tr>
                        </thead>
                        <tbody className="w-full text-sm text-left text-gray-500 bg-white">
                            {dataList.map((item, index) => (
                                <tr key={index} className="border-t">
                                    <th className="p-3">
                                        <span className="font-normal">{item.id}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{item.nickname}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{item.ip}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{item.admin_nickname}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{item.country_ar}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{item.banned_time}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{getDuration(item.duration)}</span>
                                    </th>
                                    <th className="p-3">
                                        <button onClick={() => deleteBan(item.id)}><MdDelete className='text-2xl ml-2 cursor-pointer hover:text-red-600'/></button>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                        
                    </table>

                    {dataList.length==0 && !loading && (
                        <div className={`flex w-full justify-center p-4`}>{global.translator.list_empty}</div>
                    )}

                    {loading && (
                        <div className='flex w-full justify-center p-4'>
                            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
                            </div>
                        </div>
                    )}

                    
                </div>
            </div>
            
            <Toast message={toastMessage} setToastMessage={setToastMessage} />

        </div>
    )
}



function convertTime(seconds) {
    let years = Math.floor(seconds / (365 * 24 * 60 * 60));
    let months = Math.floor((seconds % (365 * 24 * 60 * 60)) / (30 * 24 * 60 * 60));
    let days = Math.floor(((seconds % (365 * 24 * 60 * 60)) % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
    let hours = Math.floor((((seconds % (365 * 24 * 60 * 60)) % (30 * 24 * 60 * 60)) % (24 * 60 * 60)) / (60 * 60));
    let minutes = Math.floor(((((seconds % (365 * 24 * 60 * 60)) % (30 * 24 * 60 * 60)) % (24 * 60 * 60)) % (60 * 60)) / 60);
    let remainingSeconds = Math.floor((((seconds % (365 * 24 * 60 * 60)) % (30 * 24 * 60 * 60)) % (24 * 60 * 60)) % 60);

    if (years > 0) {
        return years + " years";
    } else if (months > 0) {
        return months + " months";
    } else if (days > 0) {
        return days + " days";
    } else if (hours > 0) {
        return hours + " hours";
    } else if (minutes > 0) {
        return minutes + " minutes";
    } else {
        return remainingSeconds + " seconds";
    }
}

export default BannedUsers
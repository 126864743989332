import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../api/axios'
import { MdArrowBackIosNew } from 'react-icons/md';
import GlobalContext from '../contexts/GlobalProvider'
import Common from '../utils/common'
import { TbArrowsSort } from 'react-icons/tb';

function Roots() {


    const {room_id} = useParams()
    const navigate = useNavigate()
    const { global } = useContext(GlobalContext)
    const [rootsList, setRootsList] = useState([])
    const [allRootsList, setAllRootsList] = useState([])

    const loadRootsList = async () => {
        axios.post("/rooms/roots/list", {room_id: room_id})
        .then(data => {

            var roots = data.data.return_data
            roots = JSON.parse(Common.base64Decode(roots))
            
            setRootsList(roots)
            setAllRootsList(roots)
            // setUpdatePassword(room.master_password)
            // setEmptyRoom(JSON.stringify(room) === "{}")
            // setEmptyRoomMeessage(global.translator.room_no_exists)
            return true
        })
        .catch(err => {
            navigate('/login')
        })
       
    }

    const sortData = (key) => {
        var roots = JSON.parse(JSON.stringify(rootsList))
        if(roots.length >= 2) {
            if(typeof roots[0][key] === "number") {
                if(roots[0][key] > roots[roots.length - 1][key]) roots.sort((a, b) => a[key] - b[key])
                else roots.sort((a, b) => b[key] - a[key])
            }
            else {
                if(roots[0][key] > roots[roots.length - 1][key]) roots.sort((a, b) => a[key].localeCompare(b[key]))
                else roots.sort((a, b) => b[key].localeCompare(a[key]))
            }
            
            setRootsList(roots)
        }
    }
  

    const onSearch = e => {
        var nickname = e.target.value
        var list = []
        for(let name of allRootsList) {
            if(name.nickname.includes(nickname)) list.push(name)
        }
        setRootsList(list)
    }

    useEffect(() => {
        loadRootsList()
    }, [])

    return (
        <div>

            <div className="flex items-center text-2xl mb-4 mt-2 hover:underline hover:text-main cursor-pointer" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew className="mr-3"/>
                {global.translator.room_info}
            </div>

            <div className='bg-white p-4 rounded-md border'>
                <div className='text-xl mb-2'>{global.translator.roots_list}</div>
                <input className="mb-4 border h-10 text-center form-control rounded bg-gray-50 border-gray-300" type="text" placeholder={global.translator.search} onChange={e=>onSearch(e)}/> 
                <div className="overflow-x-auto relative  rounded-md border border-gray-300">
                    <table className="w-full">
                        <thead className="w-full text-sm text-left text-gray-500 bg-gray-100">
                            <tr>
                                <th className="p-3 flex"># <TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("id")}/></th>
                                <th className="p-3"><div className='flex'>{global.translator.name}<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("nickname")}/></div></th>
                                <th className="p-3"><div className='flex'>{global.translator.expire} ({global.translator.day})<TbArrowsSort className='text-lg ml-2 cursor-pointer hover:text-main' onClick={()=>sortData("expire_days")}/></div></th>
                            </tr>
                        </thead>
                        <tbody className="w-full text-sm text-left text-gray-500 bg-white">
                            {rootsList.map((root, index) => (
                                <tr key={index} className="border-t hover:bg-gray-100 cursor-pointer" onClick={() => navigate("/rooms/"+room_id+"/roots/"+root.id)}>
                                    <th className="p-3">
                                        <span className="font-normal">{root.id}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{root.nickname}</span>
                                    </th>
                                    <th className="p-3">
                                        <span className="font-normal">{root.expire_days}</span>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
  }

export default Roots
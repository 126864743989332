import LanguageHelper from "../helpers/LanguageHelper";



function Alert(props) {

    const translator = new LanguageHelper("en")
    
    const { message, type } = props;
    
    var color = "blue"
    if(type === "danger") color = "red"
    else if(type === "success") color = "green"
    else if(type === "warning") color = "yellow"

    var style = `text-${color}-700 bg-${color}-100 border-[1px] border-${color}-400`
    return (
        message === "" ? <div></div> : (
            <div className={`p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-lg ${style}`} role="alert" dangerouslySetInnerHTML={{__html: message}}>
                {/* {message} */}
            </div>
        )
    )
}

export default Alert;

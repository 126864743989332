
import { useNavigate, Outlet } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import SideBar from '../components/sidebar';
import { AiOutlineMenu } from 'react-icons/ai';
import user from "../assets/images/user.png"
import GlobalContext from "../contexts/GlobalProvider";

function Home() {

    
    const { global } = useContext(GlobalContext)
    let navigate = useNavigate();
    const [isSideBarOpen, setIsSideBarOpen] = useState(false)
    const outsideSideBar = useRef(null)
    var image_url = user
    if(sessionStorage.getItem("image_url")) image_url = sessionStorage.getItem("image_url")

    const openSideBar = () => {
        setIsSideBarOpen(!isSideBarOpen)
    }

    const closeSideBar = () => {
        setIsSideBarOpen(false)
    }

    useEffect(() => {
        outsideSideBar.current.onclick = () => {
            setIsSideBarOpen(false)
        }
    }, [])

    return (

       
        <div className='h-screen flex scroll-smooth overflow-visible relative'>

            {/* <div className={`w-64 bg-slate-700 fixed top-0 bottom-0 z-90 transition-all ${isSideBarOpen?"-left-64":"left-0"}`}>


            </div> */}

            <SideBar visible={isSideBarOpen} close={closeSideBar}/>

            <div className={`bg-black fixed inset-0 z-20 ${isSideBarOpen?"visible opacity-30":"invisible opacity-0"} md:invisible transition-all`} ref={outsideSideBar}></div>

            <div className='bg-gray w-full flex flex-col md:ml-64'>
            
                <div className='flex flex-col grow'>
                    <nav className='bg-main h-16 w-full sticky top-0 z-10 flex items-center px-3'>
                        <AiOutlineMenu className='text-white text-3xl md:hidden' onClick={openSideBar} />
                        <div className="grow"></div>
                        <span className="text-white mx-2 font-bold">{global.translator.welcome.replace("%s", sessionStorage.getItem("name"))}</span>
                        <img src={image_url} className="w-10 rounded-full"/>

                    </nav>

                    <div className="w-full h-full bg-gray-100 p-4" id="main">
                        <Outlet />
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Home;

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import GlobalContext from "../contexts/GlobalProvider";
import Common from "../utils/common";


function Dashboard() {


    const { global } = useContext(GlobalContext)
    const navigate = useNavigate()
    const [data, setData] = useState({})


    const loadDashboardInfo = async () => {
        try {
            var data = await axios.post("/dashboard/info");
            data = data.data.return_data
            data = JSON.parse(Common.base64Decode(data))
            console.log(data)
            setData(data)
        } catch (error) {
            console.log(error)
            if(error.response.status === 401) {
                navigate('/login')
            }
        }
        return null
    }

    useEffect(() => {
        loadDashboardInfo()
    }, [])


    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                <div onClick={() => navigate("/rooms")} className="bg-white p-4 rounded shadow-sm flex flex-col hover:bg-gray-50 cursor-pointer">
                    <span className="text-xl">{global.translator.rooms}</span>
                    <span className="text-main text-xl mt-4 font-bold">{data.rooms_count}</span>
                </div>
                <div onClick={() => navigate("/names")}  className="bg-white p-4 rounded shadow-sm flex flex-col hover:bg-gray-50 cursor-pointer">
                    <span className="text-xl">{global.translator.registered_names}</span>
                    <span className="text-main text-xl mt-4 font-bold">{data.names_count}</span>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;


import lang_en from "../assets/lang/en.json"
import lang_ar from "../assets/lang/ar.json"

export default class LanguageHelper {

    constructor(lang) {
        if(lang === "en") {
            this.lang_file = lang_en
        } else {
            this.lang_file = lang_ar
        }
        
    }

    translate(text) {
        return this.lang_file[text]
    }

}

// export default LanguageHelper;

import logo from "../assets/images/logo.png"
import { RiHome6Fill, RiWechatFill } from 'react-icons/ri';
import { MdVerified } from 'react-icons/md';
import { CgLogOut, CgCloseO} from 'react-icons/cg';
import { NavLink } from "react-router-dom";
import GlobalContext from "../contexts/GlobalProvider";
import { useContext } from "react";



function SideBar(props) {

    const {visible, close} = props
    const { global } = useContext(GlobalContext)

    // [
    //     {name: global.translator.rooms_list, to: "/rooms"},
    //     {name: global.translator.roots, to: "/roots"},
    // ]

    var links = [
        {name: global.translator.home, to: "/", icon: RiHome6Fill, subItems: []},
        {name: global.translator.rooms, to: "/rooms", icon: RiWechatFill, subItems: []},
        {name: global.translator.registered_names, to: "/names", icon: MdVerified, subItems: []},
        {name: global.translator.logout, to: "/logout", icon: CgLogOut, subItems: []},
    ]

    return (
        <aside className={`w-64 h-screen z-30  fixed top-0 bottom-0 md:left-0 md:transition-none transition-all ${visible?"left-0":"-left-64"} shadow-2xl md:shadow-none`}aria-label="Sidebar">
            <div className="overflow-y-auto pb-4 px-3 bg-gray-50 rounded dark:bg-gray-800 h-full">
                
                <div className="mb-4 h-16 flex justify-center flex-col border-b border-gray-200 ">
                    <img src={logo} className="w-10 mx-2" alt=""/>
                </div>

                <ul className="space-y-2">
                    {links.map((link, index) => (
                        <li key={index}>
                            <SideBarItem Icon={link.icon} name={link.name} path={link.to} subItems={link.subItems}/>
                        </li>
                    ))}
                </ul>
            </div>

            <button className="absolute top-2 right-2 md:hidden" onClick={close}>
                <CgCloseO className="text-3xl text-gray-700"/>
            </button>
        </aside>
    );
}

export default SideBar;


function SideBarItem(props) {

    const {path, name, Icon, subItems} = props

    return (

        subItems.length === 0 ? (
            <NavLink to={path} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <Icon className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"/>
                <span className="ml-3">{name}</span>
            </NavLink>
        ) : (
            <>
                <button type="button" className="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                    <Icon aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"/>
                    <span className="flex-1 ml-3 text-left whitespace-nowrap" >{name}</span>
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
                <ul id="dropdown-example" className="hidden py-2 space-y-2">
                    {subItems.map((link, index) => (
                        <li key={index}>
                            <NavLink to={link.to} className="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                                {link.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </>
        )
    )
}

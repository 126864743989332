import './App.css';
import React from "react"
import Home from "./pages/home";
import {Routes, Route} from "react-router-dom"
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/login';
import Rooms from './pages/Rooms';
import Names from './pages/names';
import Roots from './pages/roots';
import RequireAuth from './components/RequireAuth';
import EmailVerification from './pages/twofa';
import Logout from './pages/logout';
import DetailsRoom from './pages/DetailsRoom';
import RootDetails from './pages/RootDetails';
import Users from './pages/users';
import BannedUsers from './pages/BannedUsers';
import NameDetails from './pages/NameDetails';

function App() {

    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/twofa" element={<EmailVerification />} />
            <Route path="/logout" element={<Logout />} />
            <Route element={<RequireAuth />} >
                <Route path="/" element={<Home />} >
                    <Route index element={<Dashboard />} />
                    <Route path="rooms" element={<Rooms />} />
                    <Route path="rooms/:room_id" element={<DetailsRoom />} />
                    <Route path="rooms/:room_id/roots" element={<Roots />} />
                    <Route path="rooms/:room_id/roots/:root_id" element={<RootDetails />} />
                    <Route path="rooms/:room_id/users" element={<Users />} />
                    <Route path="rooms/:room_id/banned" element={<BannedUsers />} />
                    <Route path="names" element={<Names />} />
                    <Route path="names/:name_id" element={<NameDetails />} />
                </Route>
            </Route>
            <Route path="*" element={<p>Not Found 404</p>} />
        </Routes>
    );
}

export default App;

import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams  } from "react-router-dom";
import axios from "../api/axios";
import { MdContentCopy } from 'react-icons/md';
import { MdArrowBackIosNew } from 'react-icons/md';
import GlobalContext from "../contexts/GlobalProvider";
import Common from "../utils/common.js"
import Toast from "../components/Toast";
import {CopyToClipboard} from 'react-copy-to-clipboard';



function NameDetails(props) {

    
    const {name_id} = useParams()
    const navigate = useNavigate()
    const { global } = useContext(GlobalContext)
    const [nameData, setRootData] = useState({})
    const [updatePassword, setUpdatePassword] = useState("")
    // const [editFinished, setEditFinished] = useState(true)
    const [toastMessage, setToastMessage] = useState({visible: false, message: ""})
    const [edit, setEdit] = useState(true)
    const [emptyData, setEmptyData] = useState(true)
    const codeInput = useRef(null)
    const editPasswordInput = useRef(null)
    const passwordInput = useRef(null)
    const [emptyDataMeessage, setEmptyDataMeessage] = useState("")

    
    const loadNameDetails = async () => {
        axios.post("/names/"+name_id)
        .then(data => {

            var name = data.data.return_data
            name = JSON.parse(Common.base64Decode(name))
            setRootData(name)
            setUpdatePassword(name.password)
            // passwordInput.current.value = root.password
            setEmptyData(JSON.stringify(name) === "{}")
            // setEmptyRoomMeessage(global.translator.room_no_exists)
        })
        .catch(err => {
            // navigate('/login')
        })
       
    }



    const editPassword = (e, current_password) => {
        if(edit) {
            setEdit(false)
            return
        }

        var password = editPasswordInput.current.value
        if(password.length < 8) {
            setToastMessage({
                visible: true,
                message: global.translator.password_at_least_8_char
            })
            return
        }

        var data = {
            name_id: name_id,
            current_password: Common.sha256(nameData.password),
            password: password
        }

        axios.post("/names/password/update", data)
        .then(data => {

            if(data.data.status === 1) {
                setToastMessage({
                    visible: true,
                    message: global.translator.saved
                })
                setUpdatePassword(password)
                passwordInput.current.value = password
            }
            else {
                setToastMessage({
                    visible: true,
                    message: global.translator.error
                })
            }
            setEdit(true)
            
        })
        .catch(err => {
            setEdit(true)
            navigate('/login')
        })

    }

    const getTypeName = type => {
        if(type === 0) return "--"
        if(type === 1) return global.translator.protected
        if(type === 6) return global.translator.background
        if(type === 7) return global.translator.vip
        if(type === 8) return global.translator.support
        return global.translator.normal
    }


    useEffect(() => {
        loadNameDetails()
    }, [])

    return (
        
        <div className="relative">
            
            <div className="flex items-center text-2xl mb-4 mt-2 hover:underline hover:text-main cursor-pointer" onClick={() => navigate("/", {replace: true})}>
                <MdArrowBackIosNew className="mr-3"/>
                {global.translator.names_list}
            </div>
            
            {emptyData ? (
                <div className={`w-full text-center mt-10 text-xl text-gray-700 ${emptyData?"":"hidden"}`}>{emptyDataMeessage}</div>
            ) : (
                <div>
                
                <div className="bg-white p-4 shadow-dm border rounded mt-4 overflow-auto">
                   
                    <span className="text-xl text-gray-700 border-b flex pb-2">{global.translator.name_info}</span>
                    <table className="text-left overflow-hidden mt-4">
                        <tbody className="overflow-hidden">
                            <tr>
                                <th className="pr-2 py-3">{global.translator.name}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{nameData.nickname}</td>
                            </tr>
                            <tr>
                                <th className="pr-2 py-3">{global.translator.type2}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{getTypeName(nameData.name_type)}</td>
                            </tr>
                            <tr>
                                <th className="pr-2 py-3 whitespace-nowrap">{global.translator.subscription_date}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{nameData.join_date}</td>
                            </tr>
                            <tr>
                                <th className="pr-2 py-3 whitespace-nowrap">{global.translator.end_date}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap text-gray-600">{nameData.end_date} <b className="mx-2">({nameData.expire_days} {global.translator.day})</b></td>
                            </tr>
                            <tr className="align-baseline">
                                <th className="pr-2 py-3 whitespace-nowrap center">{global.translator.password}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap flex justify-center items-center align-baseline">
                                    <div className={`flex border rounded px-3 py-1 bg-gray-50 justify-center items-center ${edit?"flex":"hidden"}`}>
                                        <input className="text-center text-gray-500 bg-gray-50 focus:outline-none" type="password" defaultValue={updatePassword} ref={passwordInput} readOnly/> 
                                        <CopyToClipboard text={updatePassword} onCopy={() => setToastMessage({message: global.translator.copied, visible: true})}>
                                            <MdContentCopy className="ml-3 text-xl text-gray-600 hover:text-main cursor-pointer"/>
                                        </CopyToClipboard>
                                    </div>
                                    <div className={`border rounded px-3 py-1 bg-gray-50 justify-center items-center ${edit?"hidden":"flex"}`}>
                                        <input className="text-center text-gray-500 bg-gray-50 focus:outline-none" type="password" defaultValue={updatePassword} ref={editPasswordInput}/> 
                                    </div>
                                    <button className="px-3 hover:text-main cursor-pointer font-bold" onClick={(e) => editPassword(e, updatePassword)}>{edit?global.translator.edit:global.translator.save}</button>
                                </td>
                            </tr>
                            <tr className="align-baseline">
                                <th className="pr-2 py-3 whitespace-nowrap center">{global.translator.code}</th>
                                <th className="pr-2">:</th>
                                <td className="whitespace-nowrap flex">
                                    <div className="flex border rounded px-3 py-1 bg-gray-50 justify-center items-center">
                                        <input className="text-center text-gray-500 bg-gray-50 focus:outline-none" type="password" defaultValue={nameData.code} ref={codeInput} readOnly/> 
                                        <CopyToClipboard text={nameData.code} onCopy={() => setToastMessage({message: global.translator.copied, visible: true})}>
                                            <MdContentCopy className="ml-3 text-xl text-gray-600 hover:text-main cursor-pointer"/>
                                        </CopyToClipboard>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            )}

            <Toast message={toastMessage} setToastMessage={setToastMessage} />
            
        </div>
    );
}


  
export default NameDetails;
  

import { useEffect } from "react";



function Toast(props) {

    const { message, setToastMessage } = props;
   
    useEffect(() => {
        if(message.visible) {
            setTimeout(() => setToastMessage({...message, visible: false}), 1000)
        }
    }, [message])

    return (
        <div className={`absolute top-0 text-center w-full flex ${!message.visible?"opacity-0 invisible":"opacity-100 visible"} transition-all duration-500`}>
            <div className="bg-black/60 shadow-md m-auto p-4 rounded-md text-gray-100">
                {message.message}
            </div>
        </div>
    )
}

export default Toast;
